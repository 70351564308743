import { format } from "date-fns";

import { GetProductsInfiniteQuery } from "@/gql/graphql";

export enum ProductsColumnHeaders {
  ID = "Id",
  MANUFACTURER_PART_NUMBER = "Mfg Part Number",
  MANUFACTURER = "Manufacturer",
  CATEGORY = "Category",
  FAMILY = "Family",
  LINE = "Line",
  MODEL = "Model",
  NAME = "Name",
  UPDATED_AT = "Updated At",
  ATTRIBUTES = "Attributes",
}

export type ProductRowData = {
  [ProductsColumnHeaders.ID]: number;
  [ProductsColumnHeaders.MANUFACTURER_PART_NUMBER]: string[];
  [ProductsColumnHeaders.MANUFACTURER]: string;
  [ProductsColumnHeaders.CATEGORY]: string;
  [ProductsColumnHeaders.FAMILY]: string;
  [ProductsColumnHeaders.LINE]: string;
  [ProductsColumnHeaders.MODEL]: string;
  [ProductsColumnHeaders.NAME]: string;
  [ProductsColumnHeaders.UPDATED_AT]: string;
  [ProductsColumnHeaders.ATTRIBUTES]: { [key: string]: string };
};

type Props = {
  products: GetProductsInfiniteQuery["products"];
};

export const getProductsTableRows = ({ products }: Props) => {
  const rows: ProductRowData[] = [];
  //   to get the rows iterate over submissions
  products.forEach((product) => {
    // const row: { [key: string]: RowValue } = {};
    const row: ProductRowData = {} as ProductRowData;
    const {
      // attributes,
      category,
      //   createdAt,
      //   description,
      family,
      id,
      line,
      manufacturer,
      manufacturerPartNumbers,
      model,
      name,
      //   searchFamily,
      //   searchLine,
      //   searchName,
      updatedAt,
    } = product;
    const lastUpdate = format(new Date(updatedAt), "MM/dd/yyyy");

    row[ProductsColumnHeaders.ID] = id;
    row[ProductsColumnHeaders.MANUFACTURER_PART_NUMBER] =
      manufacturerPartNumbers ?? [];
    row[ProductsColumnHeaders.MANUFACTURER] = manufacturer.name ?? "";
    row[ProductsColumnHeaders.CATEGORY] = category.name ?? "";
    row[ProductsColumnHeaders.FAMILY] = family ?? "";
    row[ProductsColumnHeaders.LINE] = line ?? "";
    row[ProductsColumnHeaders.MODEL] = model ?? "";
    row[ProductsColumnHeaders.NAME] = name ?? "";
    row[ProductsColumnHeaders.UPDATED_AT] = lastUpdate;
    // row[ProductsColumnHeaders.ATTRIBUTES] = attributes.values ?? {};

    rows.push(row);
  });

  return { rows };
};
