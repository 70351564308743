// import { useEffect } from "react";
// import * as Sentry from "@sentry/react";
// import {
//   createRoutesFromChildren,
//   matchRoutes,
//   useLocation,
//   useNavigationType,
// } from "react-router-dom";

// import { env } from "@/env";

export function initMonitoring() {
  console.log("sentry here");
  // Sentry.init({
  //   dsn: env.VITE_PUBLIC_SENTRY_DSN,
  //   integrations: [
  //     new Sentry.BrowserTracing({
  //       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
  //         useEffect,
  //         useLocation,
  //         useNavigationType,
  //         createRoutesFromChildren,
  //         matchRoutes,
  //       ),
  //     }),
  //     new Sentry.Replay(),
  //   ],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   tracesSampleRate: 1.0,

  //   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ["localhost", env.VITE_PUBLIC_APOLLO_API_URL],

  //   // Capture Replay for 10% of all sessions,
  //   // plus for 100% of sessions with an error
  //   replaysSessionSampleRate: 0.1,
  //   replaysOnErrorSampleRate: 1.0,
  // });
}
