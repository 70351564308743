import { graphql } from "@/gql/gql";
import { LabelScalarFieldEnum, SortOrder } from "@/gql/graphql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Props = {
  labelName: string;
  typeahead?: string;
};

export const useGetLabelTypeahead = ({ labelName, typeahead = "" }: Props) => {
  return useGraphqlQuery({
    queryKey: ["GetLabeltypeahead", labelName, typeahead],
    query: graphql(`
      query LabelTypeahead(
        $where: LabelWhereInput
        $take: Int
        $orderBy: [LabelOrderByWithRelationInput!]
        $distinct: [LabelScalarFieldEnum!]
      ) {
        labels(
          where: $where
          take: $take
          orderBy: $orderBy
          distinct: $distinct
        ) {
          id
          value
          feedback {
            content
          }
        }
      }
    `),
    variables: {
      where: {
        name: {
          equals: labelName,
        },
      },
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
      take: 20,
      distinct: LabelScalarFieldEnum.Value,
    },
  });
};
