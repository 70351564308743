import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export const useCreateSubmissionMutation = () => {
  return useGraphqlMutation({
    mutationKey: ["createSubmission"],
    mutation: graphql(`
      mutation CreateSubmission(
        $submittedFor: DateTimeISO!
        $submissionTypeId: Float!
        $vendorId: Float!
        $fileId: Float!
      ) {
        createSubmission(
          submittedFor: $submittedFor
          submissionTypeId: $submissionTypeId
          vendorId: $vendorId
          fileId: $fileId
        ) {
          id
          createdAt
          fileId
          status
          typeId
          vendorId
        }
      }
    `),
  });
};
