import { graphql } from "@/gql/gql";
import { createQueryOptions } from "@/lib/hooks/graphql";

type Props = {
  submissionId?: number | undefined;
};

export const getNormalizeTransformationOutputFile = ({
  submissionId,
}: Props) => {
  return createQueryOptions({
    query: graphql(`
      query GetNormalizeTransformation(
        $where: SubmissionWhereUniqueInput!
        $transformationsWhere2: TransformationWhereInput
        $orderBy: [TransformationOrderByWithRelationInput!]
      ) {
        submission(where: $where) {
          transformations(where: $transformationsWhere2, orderBy: $orderBy) {
            id
            outputFileId
          }
        }
      }
    `),
    variables: {
      where: { id: submissionId },
      transformationsWhere2: {
        type: {
          is: {
            template: {
              is: {
                name: {
                  equals: "Normalize Columns",
                },
              },
            },
          },
        },
      },
    },
  });
};
