import { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { HoverBorderGradient } from "@/components/ui/hover-border-gradient";
import { cn } from "@/lib/cn";
import { easeSnappy } from "@/lib/motion";
import { Header } from "../submission/:submissionId/Header";
import { SubmissionTypeUploadGrid } from "./SubmissionTypeUploadGrid";
import { VendorUploadToggleTables } from "./VendorUploadToggleTables";

export const VendorUploadPage = () => {
  const [showTypes, setShowTypes] = useState(true);
  const [parent] = useAutoAnimate({
    duration: 100,
    easing: easeSnappy,
  });

  return (
    <div className="grid max-h-screen min-h-screen grid-rows-[auto_1fr] content-stretch gap-6">
      <div className="flex w-full flex-col items-center md:px-16">
        <Header
          title="Data Steward Submissions Portal"
          description="Upload your inventory or spreadsheet files for processing and our advanced AI and validation tools will handle the rest. Simply drag and drop your files to get started."
          className="py-6 pt-24"
        />

        <HoverBorderGradient
          as="button"
          containerClassName=""
          onClick={() => setShowTypes(!showTypes)}
          className={cn(
            "min-w-[250px] hover:bg-accent hover:text-accent-foreground",
          )}
        >
          {showTypes ? "View Past Submissions" : "Create New Submission"}
        </HoverBorderGradient>
      </div>

      <div className="flex justify-center p-2 sm:p-4 lg:p-6" ref={parent}>
        {showTypes ? (
          <div className="max-w-2xl" key="upload-grid">
            <SubmissionTypeUploadGrid />
          </div>
        ) : (
          <div className="w-full" key="tables">
            <VendorUploadToggleTables />
          </div>
        )}
      </div>
    </div>
  );
};
