import { graphql } from "@/gql/gql";
import { QueryMode, SortOrder } from "@/gql/graphql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Props = {
  orgId?: number;
  typeahead?: string;
};

export const useGetVendorsTypeahead = ({ orgId, typeahead = "" }: Props) => {
  return useGraphqlQuery({
    enabled: orgId != null,
    queryKey: ["GetVendorsTypeahead", orgId, typeahead],
    query: graphql(`
      query GetVendorsTypeahead(
        $where: VendorWhereInput
        $orderBy: [VendorOrderByWithRelationInput!]
        $take: Int
      ) {
        vendors(where: $where, orderBy: $orderBy, take: $take) {
          id
          name
        }
      }
    `),
    variables: {
      take: 10,
      orderBy: [
        {
          name: SortOrder.Asc,
        },
      ],
      where: {
        organizationId: {
          equals: orgId,
        },
        name: {
          contains: typeahead,
          mode: QueryMode.Insensitive,
        },
      },
    },
  });
};
