import { graphql } from "@/gql/gql";
import { QueryMode, SortOrder } from "@/gql/graphql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";
import { useGetOrgIdOrLogout } from "@/lib/hooks/useGetOrgIdOrLogout";

import { FilterTypeaheadItem } from "../table/FilterTypeaheadTextInput";
import { UseTypeaheadHook } from "../table/TableFiltersContext";

export const useGetVendorsTypeaheadItems: UseTypeaheadHook = ({
  searchField,
  typeahead = "",
  searchVars,
  activeFilter,
}) => {
  const orgId = useGetOrgIdOrLogout();

  const queryVars = {
    where: {
      ...searchVars,
      organizationId: {
        equals: orgId,
      },
      [searchField]: {
        mode: QueryMode.Insensitive,
        contains: typeahead,
      },
    },
  };

  const { data } = useGraphqlQuery({
    queryKey: ["GetVendorTypeaheadItems", typeahead, searchVars],
    query: graphql(`
      query GetVendorsTypeaheadItems(
        $where: VendorWhereInput
        $orderBy: [VendorOrderByWithRelationInput!]
        $take: Int
      ) {
        vendors(where: $where, orderBy: $orderBy, take: $take) {
          id
          name
          vendorId
          email
        }
      }
    `),
    variables: {
      ...queryVars,
      take: 10,
      orderBy: [
        {
          name: SortOrder.Asc,
        },
      ],
      where: {
        organizationId: {
          equals: orgId,
        },
        name: {
          contains: typeahead,
          mode: QueryMode.Insensitive,
        },
      },
    },
  });

  // Transform the data into FilterTypeaheadItem[]
  const typeaheadItems: FilterTypeaheadItem[] =
    data?.vendors?.map((vendor) => ({
      // @ts-ignore - searchField is correct
      label: vendor[searchField],
      // @ts-ignore - searchField is correct
      value: vendor[searchField],
      selected:
        // @ts-ignore - searchField is correct
        activeFilter?.filterValues?.includes(vendor[searchField]) ?? false,
    })) ?? [];

  return typeaheadItems;
};
