/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  fragment PredictionCardContent_labels on Label {\n    confidence\n    updatedAt\n    value\n    name\n    id\n    feedback {\n      id\n      upvote\n      content\n    }\n  }\n": types.PredictionCardContent_LabelsFragmentDoc,
    "\n  mutation CreateManyFeedback($data: [FeedbackCreateManyInput!]!) {\n    createManyFeedback(data: $data) {\n      count\n    }\n  }\n": types.CreateManyFeedbackDocument,
    "\n  query GetPredictionsInfinite(\n    $take: Int\n    $where: PredictionWhereInput\n    $cursor: PredictionWhereUniqueInput\n  ) {\n    predictions(take: $take, where: $where, cursor: $cursor) {\n      id\n      completion\n      createdAt\n      metadata {\n        id\n        key\n        predictionId\n        updatedAt\n        value\n      }\n      labels {\n        confidence\n        updatedAt\n        value\n        name\n        id\n        feedback {\n          id\n          upvote\n          content\n        }\n      }\n      predictionId\n      prompt\n      transformation {\n        name\n        id\n      }\n      updatedAt\n    }\n  }\n": types.GetPredictionsInfiniteDocument,
    "\n  query GetAccuracyScoreCardData(\n    $currWhere: SubmissionMetaWhereInput\n    $prevWhere: SubmissionMetaWhereInput\n  ) {\n    curr: submissionMetas(where: $currWhere) {\n      key\n      value\n    }\n    prev: submissionMetas(where: $prevWhere) {\n      key\n      value\n    }\n  }\n": types.GetAccuracyScoreCardDataDocument,
    "\n  query KPMCardSubmissionCount(\n    $currWhere: SubmissionWhereInput\n    $prevWhere: SubmissionWhereInput\n  ) {\n    curr: aggregateSubmission(where: $currWhere) {\n      _count {\n        id\n      }\n    }\n    prev: aggregateSubmission(where: $prevWhere) {\n      _count {\n        id\n      }\n    }\n  }\n": types.KpmCardSubmissionCountDocument,
    "\n  query KPMCardSubmissionInProgress(\n    $currWhere: SubmissionWhereInput\n    $prevWhere: SubmissionWhereInput\n  ) {\n    curr: aggregateSubmission(where: $currWhere) {\n      _count {\n        id\n      }\n    }\n    prev: aggregateSubmission(where: $prevWhere) {\n      _count {\n        id\n      }\n    }\n  }\n": types.KpmCardSubmissionInProgressDocument,
    "\n  query GetTotalSubmissionsCardData(\n    $currWhere: SubmissionWhereInput\n    $prevWhere: SubmissionWhereInput\n  ) {\n    curr: aggregateSubmission(where: $currWhere) {\n      _count {\n        id\n      }\n    }\n    prev: aggregateSubmission(where: $prevWhere) {\n      _count {\n        id\n      }\n    }\n  }\n": types.GetTotalSubmissionsCardDataDocument,
    "\n      query GetChartSubmissionsData($where: SubmissionWhereInput) {\n        submissions(where: $where) {\n          id\n          createdAt\n        }\n      }\n    ": types.GetChartSubmissionsDataDocument,
    "\n      query GetInProgressSubmissionsCardData(\n        $currWhere: SubmissionWhereInput\n        $prevWhere: SubmissionWhereInput\n      ) {\n        curr: aggregateSubmission(where: $currWhere) {\n          _count {\n            id\n          }\n        }\n        prev: aggregateSubmission(where: $prevWhere) {\n          _count {\n            id\n          }\n        }\n      }\n    ": types.GetInProgressSubmissionsCardDataDocument,
    "\n      query GetTotalSubmissionsCardData(\n        $currWhere: SubmissionWhereInput\n        $prevWhere: SubmissionWhereInput\n      ) {\n        curr: aggregateSubmission(where: $currWhere) {\n          _count {\n            id\n          }\n        }\n        prev: aggregateSubmission(where: $prevWhere) {\n          _count {\n            id\n          }\n        }\n      }\n    ": types.GetTotalSubmissionsCardDataDocument,
    "\n      mutation UserGeneralInfo(\n        $data: UserUpdateInput!\n        $where: UserWhereUniqueInput!\n      ) {\n        updateOneUser(data: $data, where: $where) {\n          firstName\n          lastName\n          email\n        }\n      }\n    ": types.UserGeneralInfoDocument,
    "\n      query ValidationTypeList($where: ValidationTypeWhereInput) {\n        validationTypes(where: $where) {\n          id\n          name\n          submissionTypes {\n            id\n          }\n          updatedAt\n          description\n        }\n      }\n    ": types.ValidationTypeListDocument,
    "\n      query TransformationTypePage(\n        $where: TransformationTypeWhereUniqueInput!\n      ) {\n        transformationType(where: $where) {\n          name\n          parameters\n          updatedAt\n        }\n      }\n    ": types.TransformationTypePageDocument,
    "\n  query GetOrgExportSettings($where: OrganizationWhereUniqueInput!) {\n    organization(where: $where) {\n      exportDatabases {\n        id\n        name\n        dialect\n        createdAt\n        connectionString\n        exportMappings {\n          id\n          submissionType {\n            type\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n": types.GetOrgExportSettingsDocument,
    "\n      mutation CreateOrgExportDatabaseMutation(\n        $data: ExportDatabaseCreateInput!\n      ) {\n        createOneExportDatabase(data: $data) {\n          id\n        }\n      }\n    ": types.CreateOrgExportDatabaseMutationDocument,
    "\n      mutation UpdateOrgExportDatabaseMutation(\n        $data: ExportDatabaseUpdateInput!\n        $where: ExportDatabaseWhereUniqueInput!\n      ) {\n        updateOneExportDatabase(data: $data, where: $where) {\n          id\n        }\n      }\n    ": types.UpdateOrgExportDatabaseMutationDocument,
    "\n      mutation Mutation(\n        $data: OrganizationUpdateInput!\n        $where: OrganizationWhereUniqueInput!\n      ) {\n        updateOneOrganization(data: $data, where: $where) {\n          name\n          id\n        }\n      }\n    ": types.MutationDocument,
    "\n      query OrgUsersTable($where: UserWhereInput) {\n        users(where: $where) {\n          id\n          firstName\n          lastName\n          email\n          role\n        }\n      }\n    ": types.OrgUsersTableDocument,
    "\n      query OrgSubmissionTypes($where: SubmissionTypeWhereInput) {\n        submissionTypes(where: $where) {\n          description\n          id\n          name\n          icon\n          updatedAt\n          vendors {\n            id\n          }\n        }\n      }\n    ": types.OrgSubmissionTypesDocument,
    "\n  mutation UpdateSubmissionTypeHeader(\n    $data: SubmissionTypeUpdateInput!\n    $where: SubmissionTypeWhereUniqueInput!\n  ) {\n    updateOneSubmissionType(data: $data, where: $where) {\n      id\n      name\n      icon\n      outputColumns {\n        name\n        dataType\n        description\n      }\n    }\n  }\n": types.UpdateSubmissionTypeHeaderDocument,
    "\n  query SubmissionTypeEditPage($where: SubmissionTypeWhereUniqueInput!) {\n    submissionType(where: $where) {\n      id\n      name\n      description\n      icon\n      createdAt\n      updatedAt\n      frequency\n      outputColumns {\n        id\n        name\n        description\n        dataType\n      }\n      vendors {\n        id\n        email\n        name\n        isActive\n      }\n      transformationTypes {\n        id\n        name\n        description\n        index\n        updatedAt\n        parameters\n        template {\n          id\n          name\n        }\n      }\n      validationTypes {\n        id\n        name\n        description\n        updatedAt\n        parameters\n      }\n    }\n  }\n": types.SubmissionTypeEditPageDocument,
    "\n      mutation CreateManyVendors(\n        $data: [VendorCreateManyInput!]!\n        $skipDuplicates: Boolean\n      ) {\n        createManyVendor(data: $data, skipDuplicates: $skipDuplicates) {\n          count\n        }\n      }\n    ": types.CreateManyVendorsDocument,
    "\n      mutation CreateOneVendor($data: VendorCreateInput!) {\n        createOneVendor(data: $data) {\n          id\n          name\n          vendorId\n        }\n      }\n    ": types.CreateOneVendorDocument,
    "\n      mutation UpdateOneVendor(\n        $data: VendorUpdateInput!\n        $where: VendorWhereUniqueInput!\n      ) {\n        updateOneVendor(data: $data, where: $where) {\n          id\n        }\n      }\n    ": types.UpdateOneVendorDocument,
    "\n      query GetCalculatedRebateCardData($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          metadata {\n            key\n            value\n          }\n        }\n      }\n    ": types.GetCalculatedRebateCardDataDocument,
    "\n      query GetCostPerSaleCardData($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          metadata {\n            key\n            value\n          }\n        }\n      }\n    ": types.GetCostPerSaleCardDataDocument,
    "\n      query GetNewProductsCardData(\n        $where: SubmissionWhereUniqueInput!\n        $skip: Int\n        $take: Int\n      ) {\n        submission(where: $where) {\n          transformations(skip: $skip, take: $take) {\n            id\n            predictions {\n              id\n            }\n          }\n        }\n      }\n    ": types.GetNewProductsCardDataDocument,
    "\n  mutation ResetSubmission($submissionId: Float!) {\n    resetSubmission(submissionId: $submissionId) {\n      id\n    }\n  }\n": types.ResetSubmissionDocument,
    "\n      query GetSubmissionCardHeaderData($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          createdAt\n          status\n          file {\n            name\n            id\n          }\n          outputFile {\n            name\n            id\n          }\n          type {\n            id\n            type\n            name\n            icon\n          }\n          vendor {\n            id\n            name\n          }\n        }\n      }\n    ": types.GetSubmissionCardHeaderDataDocument,
    "\n      mutation setSubmissionStatusMutation(\n        $data: SubmissionUpdateInput!\n        $where: SubmissionWhereUniqueInput!\n      ) {\n        updateOneSubmission(data: $data, where: $where) {\n          status\n        }\n      }\n    ": types.SetSubmissionStatusMutationDocument,
    "\n      query GetSubmissionErrors($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          errors {\n            id\n            message\n            status\n            action\n          }\n          vendorId\n          type {\n            type\n          }\n        }\n      }\n    ": types.GetSubmissionErrorsDocument,
    "\n      query GetTransformationType($where: TransformationWhereUniqueInput!) {\n        transformation(where: $where) {\n          id\n          name\n          transformationTypeId\n        }\n      }\n    ": types.GetTransformationTypeDocument,
    "\n  query NumLabelsToReview($where: LabelWhereInput) {\n    aggregateLabel(where: $where) {\n      _count {\n        id\n      }\n    }\n  }\n": types.NumLabelsToReviewDocument,
    "\n  query TransformationsNav(\n    $where: TransformationWhereInput\n    $orderBy: [TransformationOrderByWithRelationInput!]\n  ) {\n    transformations(where: $where, orderBy: $orderBy) {\n      id\n      name\n      processed\n      updatedAt\n      outputFile {\n        name\n        id\n      }\n    }\n  }\n": types.TransformationsNavDocument,
    "\n      query GetTransformationsStackListData(\n        $where: SubmissionWhereUniqueInput!\n        $transformationsWhere2: TransformationWhereInput\n        $orderBy: [TransformationOrderByWithRelationInput!]\n      ) {\n        submission(where: $where) {\n          vendor {\n            name\n          }\n          transformations(where: $transformationsWhere2, orderBy: $orderBy) {\n            name\n            id\n            processed\n            updatedAt\n          }\n        }\n      }\n    ": types.GetTransformationsStackListDataDocument,
    "\n  query TransformationPredictionIds($where: PredictionWhereInput) {\n    predictions(where: $where) {\n      id\n    }\n  }\n": types.TransformationPredictionIdsDocument,
    "\n      query GetTotalPartnersData($where: OrganizationWhereUniqueInput!) {\n        organization(where: $where) {\n          _count {\n            vendors\n          }\n        }\n      }\n    ": types.GetTotalPartnersDataDocument,
    "\n      query VendorOverview($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          id\n          email\n          name\n          vendorId\n        }\n      }\n    ": types.VendorOverviewDocument,
    "\n      query SubmissionMappingEditPage($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          id\n        }\n      }\n    ": types.SubmissionMappingEditPageDocument,
    "\n      mutation UpdateNormalizeMapping(\n        $data: NormalizeMappingUpdateInput!\n        $where: NormalizeMappingWhereUniqueInput!\n      ) {\n        updateOneNormalizeMapping(data: $data, where: $where) {\n          id\n        }\n      }\n    ": types.UpdateNormalizeMappingDocument,
    "\n      query GetVendorData($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          id\n          vendorId\n          name\n          email\n          submissionTypes {\n            id\n            type\n          }\n        }\n      }\n    ": types.GetVendorDataDocument,
    "\n      query InferNormalizeMapping(\n        $exampleRows: [JSONObject!]!\n        $outputColumns: [OutputColumnDefinitionInput!]!\n        $inputColumns: [String!]!\n      ) {\n        inferNormalizeMapping(\n          exampleRows: $exampleRows\n          outputColumns: $outputColumns\n          inputColumns: $inputColumns\n        ) {\n          mapping\n        }\n      }\n    ": types.InferNormalizeMappingDocument,
    "\n      mutation CreateNormalizeMapping($data: NormalizeMappingCreateInput!) {\n        createOneNormalizeMapping(data: $data) {\n          id\n        }\n      }\n    ": types.CreateNormalizeMappingDocument,
    "\n      query GetVendor($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          id\n          name\n          email\n          organization {\n            name\n          }\n        }\n      }\n    ": types.GetVendorDocument,
    "\n      mutation CreateOneFileMutation($data: FileCreateInput!) {\n        createOneFile(data: $data) {\n          id\n        }\n      }\n    ": types.CreateOneFileMutationDocument,
    "\n      mutation CreateSubmission(\n        $submittedFor: DateTimeISO!\n        $submissionTypeId: Float!\n        $vendorId: Float!\n        $fileId: Float!\n      ) {\n        createSubmission(\n          submittedFor: $submittedFor\n          submissionTypeId: $submissionTypeId\n          vendorId: $vendorId\n          fileId: $fileId\n        ) {\n          id\n          createdAt\n          fileId\n          status\n          typeId\n          vendorId\n        }\n      }\n    ": types.CreateSubmissionDocument,
    "\n      query GetNormalizeVendorMappings($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          normalizeMappings {\n            id\n            mapping\n            submissionType {\n              outputColumns {\n                name\n                dataType\n                description\n              }\n              type\n              id\n            }\n          }\n        }\n      }\n    ": types.GetNormalizeVendorMappingsDocument,
    "\n      mutation prepareFileUploadMutation($fileName: String!) {\n        prepareFileUpload(fileName: $fileName) {\n          bucket\n          key\n          region\n          signedUrl\n        }\n      }\n    ": types.PrepareFileUploadMutationDocument,
    "\n      query SubmissionRoute(\n        $where: SubmissionWhereUniqueInput!\n        $normalizeMappingsWhere2: NormalizeMappingWhereInput\n      ) {\n        submission(where: $where) {\n          status\n          transformations {\n            status\n            type {\n              templateId\n            }\n          }\n          type {\n            id\n            normalizeMappings(where: $normalizeMappingsWhere2) {\n              mapping\n            }\n          }\n          fileId\n        }\n      }\n    ": types.SubmissionRouteDocument,
    "\n      query SubmissionNormalMap(\n        $where: SubmissionWhereUniqueInput!\n        $normalizeMappingsWhere2: NormalizeMappingWhereInput\n      ) {\n        submission(where: $where) {\n          type {\n            id\n            outputColumns {\n              name\n              dataType\n              description\n            }\n            normalizeMappings(where: $normalizeMappingsWhere2) {\n              id\n              mapping\n            }\n          }\n        }\n      }\n    ": types.SubmissionNormalMapDocument,
    "\n      query getSubmissionSummaryData(\n        $where: SubmissionWhereUniqueInput!\n        $transformationsWhere2: TransformationWhereInput\n        $take: Int\n        $orderBy: [FeedbackOrderByWithRelationInput!]\n      ) {\n        submission(where: $where) {\n          file {\n            name\n            createdAt\n          }\n          transformations(where: $transformationsWhere2) {\n            id\n            name\n            status\n            type {\n              name\n              id\n              description\n            }\n            metadata {\n              key\n              value\n            }\n            predictions {\n              id\n              labels {\n                name\n                id\n                feedback(take: $take, orderBy: $orderBy) {\n                  id\n                  content\n                }\n                value\n              }\n            }\n          }\n        }\n      }\n    ": types.GetSubmissionSummaryDataDocument,
    "\n      query Submission($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          outputFile {\n            id\n            name\n          }\n        }\n      }\n    ": types.SubmissionDocument,
    "\n      mutation UpdateSubmission(\n        $data: SubmissionUpdateInput!\n        $where: SubmissionWhereUniqueInput!\n      ) {\n        updateOneSubmission(data: $data, where: $where) {\n          id\n          type {\n            name\n            id\n          }\n        }\n      }\n    ": types.UpdateSubmissionDocument,
    "\n      query LabelTypeahead(\n        $where: LabelWhereInput\n        $take: Int\n        $orderBy: [LabelOrderByWithRelationInput!]\n        $distinct: [LabelScalarFieldEnum!]\n      ) {\n        labels(\n          where: $where\n          take: $take\n          orderBy: $orderBy\n          distinct: $distinct\n        ) {\n          id\n          value\n          feedback {\n            content\n          }\n        }\n      }\n    ": types.LabelTypeaheadDocument,
    "\n      query ProductTypeahead(\n        $take: Int\n        $where: ProductWhereInput\n        $distinct: [ProductScalarFieldEnum!]\n        $orderBy: [ProductOrderByWithRelationInput!]\n      ) {\n        products(\n          take: $take\n          where: $where\n          distinct: $distinct\n          orderBy: $orderBy\n        ) {\n          id\n          category {\n            id\n          }\n          family\n          description\n          line\n          manufacturer {\n            id\n          }\n          manufacturerPartNumbers\n          model\n          name\n          searchFamily\n          searchLine\n          searchName\n          updatedAt\n          createdAt\n          attributes {\n            id\n          }\n          id\n        }\n      }\n    ": types.ProductTypeaheadDocument,
    "\n      query GetProductTypeaheadItems(\n        $take: Int\n        $where: ProductWhereInput\n        $distinct: [ProductScalarFieldEnum!]\n        $orderBy: [ProductOrderByWithRelationInput!]\n      ) {\n        products(\n          take: $take\n          where: $where\n          distinct: $distinct\n          orderBy: $orderBy\n        ) {\n          id\n          category {\n            id\n          }\n          family\n          description\n          line\n          manufacturer {\n            id\n          }\n          manufacturerPartNumbers\n          model\n          name\n          searchFamily\n          searchLine\n          searchName\n          updatedAt\n          createdAt\n          attributes {\n            id\n          }\n        }\n      }\n    ": types.GetProductTypeaheadItemsDocument,
    "\n  query GetProductsInfinite(\n    $take: Int\n    $where: ProductWhereInput\n    $orderBy: [ProductOrderByWithRelationInput!]\n    $cursor: ProductWhereUniqueInput\n    $aggregateProductWhere2: ProductWhereInput\n  ) {\n    products(take: $take, where: $where, orderBy: $orderBy, cursor: $cursor) {\n      attributes {\n        id\n        value\n      }\n      category {\n        id\n        name\n      }\n      createdAt\n      description\n      family\n      id\n      line\n      manufacturer {\n        id\n        name\n      }\n      manufacturerPartNumbers\n      model\n      name\n      searchFamily\n      searchLine\n      searchName\n      updatedAt\n    }\n    aggregateProduct(where: $aggregateProductWhere2) {\n      _count {\n        id\n      }\n    }\n  }\n": types.GetProductsInfiniteDocument,
    "\n      query GetSubmissionsNameTypeahead(\n        $take: Int\n        $orderBy: [SubmissionOrderByWithRelationInput!]\n        $where: SubmissionWhereInput\n      ) {\n        submissions(take: $take, orderBy: $orderBy, where: $where) {\n          id\n          file {\n            name\n          }\n        }\n      }\n    ": types.GetSubmissionsNameTypeaheadDocument,
    "\n      query GetVendorsTypeahead(\n        $where: VendorWhereInput\n        $orderBy: [VendorOrderByWithRelationInput!]\n        $take: Int\n      ) {\n        vendors(where: $where, orderBy: $orderBy, take: $take) {\n          id\n          name\n        }\n      }\n    ": types.GetVendorsTypeaheadDocument,
    "\n  query GetSubmissionsInfinite(\n    $where: SubmissionWhereInput\n    $take: Int\n    $cursor: SubmissionWhereUniqueInput\n    $orderBy: [SubmissionOrderByWithRelationInput!]\n    $aggregateSubmissionWhere2: SubmissionWhereInput\n  ) {\n    submissions(\n      where: $where\n      take: $take\n      cursor: $cursor\n      orderBy: $orderBy\n    ) {\n      id\n      createdAt\n      submittedFor\n      metadata {\n        value\n        key\n      }\n      type {\n        name\n        type\n      }\n      file {\n        id\n        name\n      }\n      outputFile {\n        id\n        name\n      }\n      vendor {\n        id\n        name\n      }\n      transformations {\n        id\n      }\n      validations {\n        id\n      }\n      status\n      validationStatus\n    }\n    aggregateSubmission(where: $aggregateSubmissionWhere2) {\n      _count {\n        id\n      }\n    }\n  }\n": types.GetSubmissionsInfiniteDocument,
    "\n      query getSubmissionTransformations(\n        $where: SubmissionWhereUniqueInput!\n        $transformationsWhere2: TransformationWhereInput\n      ) {\n        submission(where: $where) {\n          transformations(where: $transformationsWhere2) {\n            processed\n            outputFile {\n              name\n              id\n            }\n            id\n            name\n            status\n            type {\n              templateId\n              name\n              id\n              description\n            }\n            metadata {\n              key\n              value\n            }\n          }\n        }\n      }\n    ": types.GetSubmissionTransformationsDocument,
    "\n  query PredictionRows(\n    $where: PredictionWhereInput\n    $feedbackOrderBy: [FeedbackOrderByWithRelationInput!] = { createdAt: desc }\n    $feedbackTake: Int = 1\n  ) {\n    predictions(where: $where) {\n      transformation {\n        type {\n          category\n        }\n      }\n      id\n      createdAt\n      metadata {\n        key\n        value\n        updatedAt\n      }\n      labels {\n        id\n        value\n        name\n        confidence\n        updatedAt\n        feedback(orderBy: $feedbackOrderBy, take: $feedbackTake) {\n          id\n          upvote\n          content\n        }\n      }\n    }\n  }\n": types.PredictionRowsDocument,
    "\n  mutation SubmitManyFeedback($data: [FeedbackCreateManyInput!]!) {\n    createManyFeedback(data: $data) {\n      count\n    }\n  }\n": types.SubmitManyFeedbackDocument,
    "\n      query GetNormalizeTransformation(\n        $where: SubmissionWhereUniqueInput!\n        $transformationsWhere2: TransformationWhereInput\n        $orderBy: [TransformationOrderByWithRelationInput!]\n      ) {\n        submission(where: $where) {\n          transformations(where: $transformationsWhere2, orderBy: $orderBy) {\n            id\n            outputFileId\n          }\n        }\n      }\n    ": types.GetNormalizeTransformationDocument,
    "\n      query GetSubmissionType($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          id\n          type {\n            id\n            name\n            outputColumns {\n              name\n              dataType\n              description\n            }\n          }\n        }\n      }\n    ": types.GetSubmissionTypeDocument,
    "\n  query GetVendorsInfinite(\n    $cursor: VendorWhereUniqueInput\n    $where: VendorWhereInput\n    $take: Int\n    $aggregateVendorWhere2: VendorWhereInput\n  ) {\n    vendors(cursor: $cursor, take: $take, where: $where) {\n      id\n      vendorId\n      name\n      email\n      totalSubmissions: _count {\n        submissions\n      }\n    }\n    aggregateVendor(where: $aggregateVendorWhere2) {\n      _count {\n        id\n      }\n    }\n  }\n": types.GetVendorsInfiniteDocument,
    "\n      query GetVendorsTypeaheadItems(\n        $where: VendorWhereInput\n        $orderBy: [VendorOrderByWithRelationInput!]\n        $take: Int\n      ) {\n        vendors(where: $where, orderBy: $orderBy, take: $take) {\n          id\n          name\n          vendorId\n          email\n        }\n      }\n    ": types.GetVendorsTypeaheadItemsDocument,
    "\n      mutation PrepareFileDownload($fileId: Float!) {\n        prepareFileDownload(fileId: $fileId) {\n          signedUrl\n        }\n      }\n    ": types.PrepareFileDownloadDocument,
    "\n  mutation ProcessSubmission($submissionId: Float!) {\n    processSubmission(submissionId: $submissionId) {\n      id\n    }\n  }\n": types.ProcessSubmissionDocument,
    "\n      mutation ResetSubmission($submissionId: Float!) {\n        resetSubmission(submissionId: $submissionId) {\n          id\n        }\n      }\n    ": types.ResetSubmissionDocument,
    "\n  mutation UpdateTransformation(\n    $data: TransformationUpdateInput!\n    $where: TransformationWhereUniqueInput!\n  ) {\n    updateOneTransformation(data: $data, where: $where) {\n      id\n    }\n  }\n": types.UpdateTransformationDocument,
    "\n      query Organization($where: OrganizationWhereUniqueInput!) {\n        organization(where: $where) {\n          id\n          name\n        }\n      }\n    ": types.OrganizationDocument,
    "\n  query SubmissionTypes($where: SubmissionTypeWhereInput) {\n    submissionTypes(where: $where) {\n      id\n      createdAt\n      updatedAt\n      name\n      description\n      organizationId\n      outputColumns {\n        name\n        dataType\n        description\n      }\n      type\n    }\n  }\n": types.SubmissionTypesDocument,
    "\n      query getVendorSubmissionTypes($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          submissionTypes {\n            id\n            createdAt\n            updatedAt\n            name\n            description\n            organizationId\n            outputColumns {\n              name\n              dataType\n              description\n            }\n            type\n          }\n        }\n      }\n    ": types.GetVendorSubmissionTypesDocument,
    "\n  query UserById($where: UserWhereUniqueInput!) {\n    user(where: $where) {\n      email\n      createdAt\n      firstName\n      id\n      lastName\n      organization {\n        id\n        name\n      }\n      partnerId\n      role\n      updatedAt\n    }\n  }\n": types.UserByIdDocument,
    "\n      query VendorSubmissionTypes($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          normalizeMappings {\n            id\n            submissionType {\n              id\n              name\n              outputColumns {\n                name\n                dataType\n                description\n              }\n              updatedAt\n            }\n            mapping\n            examples\n          }\n        }\n      }\n    ": types.VendorSubmissionTypesDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PredictionCardContent_labels on Label {\n    confidence\n    updatedAt\n    value\n    name\n    id\n    feedback {\n      id\n      upvote\n      content\n    }\n  }\n"): (typeof documents)["\n  fragment PredictionCardContent_labels on Label {\n    confidence\n    updatedAt\n    value\n    name\n    id\n    feedback {\n      id\n      upvote\n      content\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateManyFeedback($data: [FeedbackCreateManyInput!]!) {\n    createManyFeedback(data: $data) {\n      count\n    }\n  }\n"): (typeof documents)["\n  mutation CreateManyFeedback($data: [FeedbackCreateManyInput!]!) {\n    createManyFeedback(data: $data) {\n      count\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPredictionsInfinite(\n    $take: Int\n    $where: PredictionWhereInput\n    $cursor: PredictionWhereUniqueInput\n  ) {\n    predictions(take: $take, where: $where, cursor: $cursor) {\n      id\n      completion\n      createdAt\n      metadata {\n        id\n        key\n        predictionId\n        updatedAt\n        value\n      }\n      labels {\n        confidence\n        updatedAt\n        value\n        name\n        id\n        feedback {\n          id\n          upvote\n          content\n        }\n      }\n      predictionId\n      prompt\n      transformation {\n        name\n        id\n      }\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query GetPredictionsInfinite(\n    $take: Int\n    $where: PredictionWhereInput\n    $cursor: PredictionWhereUniqueInput\n  ) {\n    predictions(take: $take, where: $where, cursor: $cursor) {\n      id\n      completion\n      createdAt\n      metadata {\n        id\n        key\n        predictionId\n        updatedAt\n        value\n      }\n      labels {\n        confidence\n        updatedAt\n        value\n        name\n        id\n        feedback {\n          id\n          upvote\n          content\n        }\n      }\n      predictionId\n      prompt\n      transformation {\n        name\n        id\n      }\n      updatedAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAccuracyScoreCardData(\n    $currWhere: SubmissionMetaWhereInput\n    $prevWhere: SubmissionMetaWhereInput\n  ) {\n    curr: submissionMetas(where: $currWhere) {\n      key\n      value\n    }\n    prev: submissionMetas(where: $prevWhere) {\n      key\n      value\n    }\n  }\n"): (typeof documents)["\n  query GetAccuracyScoreCardData(\n    $currWhere: SubmissionMetaWhereInput\n    $prevWhere: SubmissionMetaWhereInput\n  ) {\n    curr: submissionMetas(where: $currWhere) {\n      key\n      value\n    }\n    prev: submissionMetas(where: $prevWhere) {\n      key\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query KPMCardSubmissionCount(\n    $currWhere: SubmissionWhereInput\n    $prevWhere: SubmissionWhereInput\n  ) {\n    curr: aggregateSubmission(where: $currWhere) {\n      _count {\n        id\n      }\n    }\n    prev: aggregateSubmission(where: $prevWhere) {\n      _count {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query KPMCardSubmissionCount(\n    $currWhere: SubmissionWhereInput\n    $prevWhere: SubmissionWhereInput\n  ) {\n    curr: aggregateSubmission(where: $currWhere) {\n      _count {\n        id\n      }\n    }\n    prev: aggregateSubmission(where: $prevWhere) {\n      _count {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query KPMCardSubmissionInProgress(\n    $currWhere: SubmissionWhereInput\n    $prevWhere: SubmissionWhereInput\n  ) {\n    curr: aggregateSubmission(where: $currWhere) {\n      _count {\n        id\n      }\n    }\n    prev: aggregateSubmission(where: $prevWhere) {\n      _count {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query KPMCardSubmissionInProgress(\n    $currWhere: SubmissionWhereInput\n    $prevWhere: SubmissionWhereInput\n  ) {\n    curr: aggregateSubmission(where: $currWhere) {\n      _count {\n        id\n      }\n    }\n    prev: aggregateSubmission(where: $prevWhere) {\n      _count {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetTotalSubmissionsCardData(\n    $currWhere: SubmissionWhereInput\n    $prevWhere: SubmissionWhereInput\n  ) {\n    curr: aggregateSubmission(where: $currWhere) {\n      _count {\n        id\n      }\n    }\n    prev: aggregateSubmission(where: $prevWhere) {\n      _count {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTotalSubmissionsCardData(\n    $currWhere: SubmissionWhereInput\n    $prevWhere: SubmissionWhereInput\n  ) {\n    curr: aggregateSubmission(where: $currWhere) {\n      _count {\n        id\n      }\n    }\n    prev: aggregateSubmission(where: $prevWhere) {\n      _count {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetChartSubmissionsData($where: SubmissionWhereInput) {\n        submissions(where: $where) {\n          id\n          createdAt\n        }\n      }\n    "): (typeof documents)["\n      query GetChartSubmissionsData($where: SubmissionWhereInput) {\n        submissions(where: $where) {\n          id\n          createdAt\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetInProgressSubmissionsCardData(\n        $currWhere: SubmissionWhereInput\n        $prevWhere: SubmissionWhereInput\n      ) {\n        curr: aggregateSubmission(where: $currWhere) {\n          _count {\n            id\n          }\n        }\n        prev: aggregateSubmission(where: $prevWhere) {\n          _count {\n            id\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetInProgressSubmissionsCardData(\n        $currWhere: SubmissionWhereInput\n        $prevWhere: SubmissionWhereInput\n      ) {\n        curr: aggregateSubmission(where: $currWhere) {\n          _count {\n            id\n          }\n        }\n        prev: aggregateSubmission(where: $prevWhere) {\n          _count {\n            id\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetTotalSubmissionsCardData(\n        $currWhere: SubmissionWhereInput\n        $prevWhere: SubmissionWhereInput\n      ) {\n        curr: aggregateSubmission(where: $currWhere) {\n          _count {\n            id\n          }\n        }\n        prev: aggregateSubmission(where: $prevWhere) {\n          _count {\n            id\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetTotalSubmissionsCardData(\n        $currWhere: SubmissionWhereInput\n        $prevWhere: SubmissionWhereInput\n      ) {\n        curr: aggregateSubmission(where: $currWhere) {\n          _count {\n            id\n          }\n        }\n        prev: aggregateSubmission(where: $prevWhere) {\n          _count {\n            id\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation UserGeneralInfo(\n        $data: UserUpdateInput!\n        $where: UserWhereUniqueInput!\n      ) {\n        updateOneUser(data: $data, where: $where) {\n          firstName\n          lastName\n          email\n        }\n      }\n    "): (typeof documents)["\n      mutation UserGeneralInfo(\n        $data: UserUpdateInput!\n        $where: UserWhereUniqueInput!\n      ) {\n        updateOneUser(data: $data, where: $where) {\n          firstName\n          lastName\n          email\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query ValidationTypeList($where: ValidationTypeWhereInput) {\n        validationTypes(where: $where) {\n          id\n          name\n          submissionTypes {\n            id\n          }\n          updatedAt\n          description\n        }\n      }\n    "): (typeof documents)["\n      query ValidationTypeList($where: ValidationTypeWhereInput) {\n        validationTypes(where: $where) {\n          id\n          name\n          submissionTypes {\n            id\n          }\n          updatedAt\n          description\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query TransformationTypePage(\n        $where: TransformationTypeWhereUniqueInput!\n      ) {\n        transformationType(where: $where) {\n          name\n          parameters\n          updatedAt\n        }\n      }\n    "): (typeof documents)["\n      query TransformationTypePage(\n        $where: TransformationTypeWhereUniqueInput!\n      ) {\n        transformationType(where: $where) {\n          name\n          parameters\n          updatedAt\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetOrgExportSettings($where: OrganizationWhereUniqueInput!) {\n    organization(where: $where) {\n      exportDatabases {\n        id\n        name\n        dialect\n        createdAt\n        connectionString\n        exportMappings {\n          id\n          submissionType {\n            type\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetOrgExportSettings($where: OrganizationWhereUniqueInput!) {\n    organization(where: $where) {\n      exportDatabases {\n        id\n        name\n        dialect\n        createdAt\n        connectionString\n        exportMappings {\n          id\n          submissionType {\n            type\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation CreateOrgExportDatabaseMutation(\n        $data: ExportDatabaseCreateInput!\n      ) {\n        createOneExportDatabase(data: $data) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateOrgExportDatabaseMutation(\n        $data: ExportDatabaseCreateInput!\n      ) {\n        createOneExportDatabase(data: $data) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation UpdateOrgExportDatabaseMutation(\n        $data: ExportDatabaseUpdateInput!\n        $where: ExportDatabaseWhereUniqueInput!\n      ) {\n        updateOneExportDatabase(data: $data, where: $where) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation UpdateOrgExportDatabaseMutation(\n        $data: ExportDatabaseUpdateInput!\n        $where: ExportDatabaseWhereUniqueInput!\n      ) {\n        updateOneExportDatabase(data: $data, where: $where) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation Mutation(\n        $data: OrganizationUpdateInput!\n        $where: OrganizationWhereUniqueInput!\n      ) {\n        updateOneOrganization(data: $data, where: $where) {\n          name\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation Mutation(\n        $data: OrganizationUpdateInput!\n        $where: OrganizationWhereUniqueInput!\n      ) {\n        updateOneOrganization(data: $data, where: $where) {\n          name\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query OrgUsersTable($where: UserWhereInput) {\n        users(where: $where) {\n          id\n          firstName\n          lastName\n          email\n          role\n        }\n      }\n    "): (typeof documents)["\n      query OrgUsersTable($where: UserWhereInput) {\n        users(where: $where) {\n          id\n          firstName\n          lastName\n          email\n          role\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query OrgSubmissionTypes($where: SubmissionTypeWhereInput) {\n        submissionTypes(where: $where) {\n          description\n          id\n          name\n          icon\n          updatedAt\n          vendors {\n            id\n          }\n        }\n      }\n    "): (typeof documents)["\n      query OrgSubmissionTypes($where: SubmissionTypeWhereInput) {\n        submissionTypes(where: $where) {\n          description\n          id\n          name\n          icon\n          updatedAt\n          vendors {\n            id\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSubmissionTypeHeader(\n    $data: SubmissionTypeUpdateInput!\n    $where: SubmissionTypeWhereUniqueInput!\n  ) {\n    updateOneSubmissionType(data: $data, where: $where) {\n      id\n      name\n      icon\n      outputColumns {\n        name\n        dataType\n        description\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSubmissionTypeHeader(\n    $data: SubmissionTypeUpdateInput!\n    $where: SubmissionTypeWhereUniqueInput!\n  ) {\n    updateOneSubmissionType(data: $data, where: $where) {\n      id\n      name\n      icon\n      outputColumns {\n        name\n        dataType\n        description\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SubmissionTypeEditPage($where: SubmissionTypeWhereUniqueInput!) {\n    submissionType(where: $where) {\n      id\n      name\n      description\n      icon\n      createdAt\n      updatedAt\n      frequency\n      outputColumns {\n        id\n        name\n        description\n        dataType\n      }\n      vendors {\n        id\n        email\n        name\n        isActive\n      }\n      transformationTypes {\n        id\n        name\n        description\n        index\n        updatedAt\n        parameters\n        template {\n          id\n          name\n        }\n      }\n      validationTypes {\n        id\n        name\n        description\n        updatedAt\n        parameters\n      }\n    }\n  }\n"): (typeof documents)["\n  query SubmissionTypeEditPage($where: SubmissionTypeWhereUniqueInput!) {\n    submissionType(where: $where) {\n      id\n      name\n      description\n      icon\n      createdAt\n      updatedAt\n      frequency\n      outputColumns {\n        id\n        name\n        description\n        dataType\n      }\n      vendors {\n        id\n        email\n        name\n        isActive\n      }\n      transformationTypes {\n        id\n        name\n        description\n        index\n        updatedAt\n        parameters\n        template {\n          id\n          name\n        }\n      }\n      validationTypes {\n        id\n        name\n        description\n        updatedAt\n        parameters\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation CreateManyVendors(\n        $data: [VendorCreateManyInput!]!\n        $skipDuplicates: Boolean\n      ) {\n        createManyVendor(data: $data, skipDuplicates: $skipDuplicates) {\n          count\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateManyVendors(\n        $data: [VendorCreateManyInput!]!\n        $skipDuplicates: Boolean\n      ) {\n        createManyVendor(data: $data, skipDuplicates: $skipDuplicates) {\n          count\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation CreateOneVendor($data: VendorCreateInput!) {\n        createOneVendor(data: $data) {\n          id\n          name\n          vendorId\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateOneVendor($data: VendorCreateInput!) {\n        createOneVendor(data: $data) {\n          id\n          name\n          vendorId\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation UpdateOneVendor(\n        $data: VendorUpdateInput!\n        $where: VendorWhereUniqueInput!\n      ) {\n        updateOneVendor(data: $data, where: $where) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation UpdateOneVendor(\n        $data: VendorUpdateInput!\n        $where: VendorWhereUniqueInput!\n      ) {\n        updateOneVendor(data: $data, where: $where) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetCalculatedRebateCardData($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          metadata {\n            key\n            value\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetCalculatedRebateCardData($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          metadata {\n            key\n            value\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetCostPerSaleCardData($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          metadata {\n            key\n            value\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetCostPerSaleCardData($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          metadata {\n            key\n            value\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetNewProductsCardData(\n        $where: SubmissionWhereUniqueInput!\n        $skip: Int\n        $take: Int\n      ) {\n        submission(where: $where) {\n          transformations(skip: $skip, take: $take) {\n            id\n            predictions {\n              id\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetNewProductsCardData(\n        $where: SubmissionWhereUniqueInput!\n        $skip: Int\n        $take: Int\n      ) {\n        submission(where: $where) {\n          transformations(skip: $skip, take: $take) {\n            id\n            predictions {\n              id\n            }\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ResetSubmission($submissionId: Float!) {\n    resetSubmission(submissionId: $submissionId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation ResetSubmission($submissionId: Float!) {\n    resetSubmission(submissionId: $submissionId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetSubmissionCardHeaderData($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          createdAt\n          status\n          file {\n            name\n            id\n          }\n          outputFile {\n            name\n            id\n          }\n          type {\n            id\n            type\n            name\n            icon\n          }\n          vendor {\n            id\n            name\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetSubmissionCardHeaderData($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          createdAt\n          status\n          file {\n            name\n            id\n          }\n          outputFile {\n            name\n            id\n          }\n          type {\n            id\n            type\n            name\n            icon\n          }\n          vendor {\n            id\n            name\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation setSubmissionStatusMutation(\n        $data: SubmissionUpdateInput!\n        $where: SubmissionWhereUniqueInput!\n      ) {\n        updateOneSubmission(data: $data, where: $where) {\n          status\n        }\n      }\n    "): (typeof documents)["\n      mutation setSubmissionStatusMutation(\n        $data: SubmissionUpdateInput!\n        $where: SubmissionWhereUniqueInput!\n      ) {\n        updateOneSubmission(data: $data, where: $where) {\n          status\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetSubmissionErrors($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          errors {\n            id\n            message\n            status\n            action\n          }\n          vendorId\n          type {\n            type\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetSubmissionErrors($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          errors {\n            id\n            message\n            status\n            action\n          }\n          vendorId\n          type {\n            type\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetTransformationType($where: TransformationWhereUniqueInput!) {\n        transformation(where: $where) {\n          id\n          name\n          transformationTypeId\n        }\n      }\n    "): (typeof documents)["\n      query GetTransformationType($where: TransformationWhereUniqueInput!) {\n        transformation(where: $where) {\n          id\n          name\n          transformationTypeId\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query NumLabelsToReview($where: LabelWhereInput) {\n    aggregateLabel(where: $where) {\n      _count {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query NumLabelsToReview($where: LabelWhereInput) {\n    aggregateLabel(where: $where) {\n      _count {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TransformationsNav(\n    $where: TransformationWhereInput\n    $orderBy: [TransformationOrderByWithRelationInput!]\n  ) {\n    transformations(where: $where, orderBy: $orderBy) {\n      id\n      name\n      processed\n      updatedAt\n      outputFile {\n        name\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query TransformationsNav(\n    $where: TransformationWhereInput\n    $orderBy: [TransformationOrderByWithRelationInput!]\n  ) {\n    transformations(where: $where, orderBy: $orderBy) {\n      id\n      name\n      processed\n      updatedAt\n      outputFile {\n        name\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetTransformationsStackListData(\n        $where: SubmissionWhereUniqueInput!\n        $transformationsWhere2: TransformationWhereInput\n        $orderBy: [TransformationOrderByWithRelationInput!]\n      ) {\n        submission(where: $where) {\n          vendor {\n            name\n          }\n          transformations(where: $transformationsWhere2, orderBy: $orderBy) {\n            name\n            id\n            processed\n            updatedAt\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetTransformationsStackListData(\n        $where: SubmissionWhereUniqueInput!\n        $transformationsWhere2: TransformationWhereInput\n        $orderBy: [TransformationOrderByWithRelationInput!]\n      ) {\n        submission(where: $where) {\n          vendor {\n            name\n          }\n          transformations(where: $transformationsWhere2, orderBy: $orderBy) {\n            name\n            id\n            processed\n            updatedAt\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TransformationPredictionIds($where: PredictionWhereInput) {\n    predictions(where: $where) {\n      id\n    }\n  }\n"): (typeof documents)["\n  query TransformationPredictionIds($where: PredictionWhereInput) {\n    predictions(where: $where) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetTotalPartnersData($where: OrganizationWhereUniqueInput!) {\n        organization(where: $where) {\n          _count {\n            vendors\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetTotalPartnersData($where: OrganizationWhereUniqueInput!) {\n        organization(where: $where) {\n          _count {\n            vendors\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query VendorOverview($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          id\n          email\n          name\n          vendorId\n        }\n      }\n    "): (typeof documents)["\n      query VendorOverview($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          id\n          email\n          name\n          vendorId\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query SubmissionMappingEditPage($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      query SubmissionMappingEditPage($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation UpdateNormalizeMapping(\n        $data: NormalizeMappingUpdateInput!\n        $where: NormalizeMappingWhereUniqueInput!\n      ) {\n        updateOneNormalizeMapping(data: $data, where: $where) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation UpdateNormalizeMapping(\n        $data: NormalizeMappingUpdateInput!\n        $where: NormalizeMappingWhereUniqueInput!\n      ) {\n        updateOneNormalizeMapping(data: $data, where: $where) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetVendorData($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          id\n          vendorId\n          name\n          email\n          submissionTypes {\n            id\n            type\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetVendorData($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          id\n          vendorId\n          name\n          email\n          submissionTypes {\n            id\n            type\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query InferNormalizeMapping(\n        $exampleRows: [JSONObject!]!\n        $outputColumns: [OutputColumnDefinitionInput!]!\n        $inputColumns: [String!]!\n      ) {\n        inferNormalizeMapping(\n          exampleRows: $exampleRows\n          outputColumns: $outputColumns\n          inputColumns: $inputColumns\n        ) {\n          mapping\n        }\n      }\n    "): (typeof documents)["\n      query InferNormalizeMapping(\n        $exampleRows: [JSONObject!]!\n        $outputColumns: [OutputColumnDefinitionInput!]!\n        $inputColumns: [String!]!\n      ) {\n        inferNormalizeMapping(\n          exampleRows: $exampleRows\n          outputColumns: $outputColumns\n          inputColumns: $inputColumns\n        ) {\n          mapping\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation CreateNormalizeMapping($data: NormalizeMappingCreateInput!) {\n        createOneNormalizeMapping(data: $data) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateNormalizeMapping($data: NormalizeMappingCreateInput!) {\n        createOneNormalizeMapping(data: $data) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetVendor($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          id\n          name\n          email\n          organization {\n            name\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetVendor($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          id\n          name\n          email\n          organization {\n            name\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation CreateOneFileMutation($data: FileCreateInput!) {\n        createOneFile(data: $data) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateOneFileMutation($data: FileCreateInput!) {\n        createOneFile(data: $data) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation CreateSubmission(\n        $submittedFor: DateTimeISO!\n        $submissionTypeId: Float!\n        $vendorId: Float!\n        $fileId: Float!\n      ) {\n        createSubmission(\n          submittedFor: $submittedFor\n          submissionTypeId: $submissionTypeId\n          vendorId: $vendorId\n          fileId: $fileId\n        ) {\n          id\n          createdAt\n          fileId\n          status\n          typeId\n          vendorId\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateSubmission(\n        $submittedFor: DateTimeISO!\n        $submissionTypeId: Float!\n        $vendorId: Float!\n        $fileId: Float!\n      ) {\n        createSubmission(\n          submittedFor: $submittedFor\n          submissionTypeId: $submissionTypeId\n          vendorId: $vendorId\n          fileId: $fileId\n        ) {\n          id\n          createdAt\n          fileId\n          status\n          typeId\n          vendorId\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetNormalizeVendorMappings($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          normalizeMappings {\n            id\n            mapping\n            submissionType {\n              outputColumns {\n                name\n                dataType\n                description\n              }\n              type\n              id\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetNormalizeVendorMappings($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          normalizeMappings {\n            id\n            mapping\n            submissionType {\n              outputColumns {\n                name\n                dataType\n                description\n              }\n              type\n              id\n            }\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation prepareFileUploadMutation($fileName: String!) {\n        prepareFileUpload(fileName: $fileName) {\n          bucket\n          key\n          region\n          signedUrl\n        }\n      }\n    "): (typeof documents)["\n      mutation prepareFileUploadMutation($fileName: String!) {\n        prepareFileUpload(fileName: $fileName) {\n          bucket\n          key\n          region\n          signedUrl\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query SubmissionRoute(\n        $where: SubmissionWhereUniqueInput!\n        $normalizeMappingsWhere2: NormalizeMappingWhereInput\n      ) {\n        submission(where: $where) {\n          status\n          transformations {\n            status\n            type {\n              templateId\n            }\n          }\n          type {\n            id\n            normalizeMappings(where: $normalizeMappingsWhere2) {\n              mapping\n            }\n          }\n          fileId\n        }\n      }\n    "): (typeof documents)["\n      query SubmissionRoute(\n        $where: SubmissionWhereUniqueInput!\n        $normalizeMappingsWhere2: NormalizeMappingWhereInput\n      ) {\n        submission(where: $where) {\n          status\n          transformations {\n            status\n            type {\n              templateId\n            }\n          }\n          type {\n            id\n            normalizeMappings(where: $normalizeMappingsWhere2) {\n              mapping\n            }\n          }\n          fileId\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query SubmissionNormalMap(\n        $where: SubmissionWhereUniqueInput!\n        $normalizeMappingsWhere2: NormalizeMappingWhereInput\n      ) {\n        submission(where: $where) {\n          type {\n            id\n            outputColumns {\n              name\n              dataType\n              description\n            }\n            normalizeMappings(where: $normalizeMappingsWhere2) {\n              id\n              mapping\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query SubmissionNormalMap(\n        $where: SubmissionWhereUniqueInput!\n        $normalizeMappingsWhere2: NormalizeMappingWhereInput\n      ) {\n        submission(where: $where) {\n          type {\n            id\n            outputColumns {\n              name\n              dataType\n              description\n            }\n            normalizeMappings(where: $normalizeMappingsWhere2) {\n              id\n              mapping\n            }\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query getSubmissionSummaryData(\n        $where: SubmissionWhereUniqueInput!\n        $transformationsWhere2: TransformationWhereInput\n        $take: Int\n        $orderBy: [FeedbackOrderByWithRelationInput!]\n      ) {\n        submission(where: $where) {\n          file {\n            name\n            createdAt\n          }\n          transformations(where: $transformationsWhere2) {\n            id\n            name\n            status\n            type {\n              name\n              id\n              description\n            }\n            metadata {\n              key\n              value\n            }\n            predictions {\n              id\n              labels {\n                name\n                id\n                feedback(take: $take, orderBy: $orderBy) {\n                  id\n                  content\n                }\n                value\n              }\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query getSubmissionSummaryData(\n        $where: SubmissionWhereUniqueInput!\n        $transformationsWhere2: TransformationWhereInput\n        $take: Int\n        $orderBy: [FeedbackOrderByWithRelationInput!]\n      ) {\n        submission(where: $where) {\n          file {\n            name\n            createdAt\n          }\n          transformations(where: $transformationsWhere2) {\n            id\n            name\n            status\n            type {\n              name\n              id\n              description\n            }\n            metadata {\n              key\n              value\n            }\n            predictions {\n              id\n              labels {\n                name\n                id\n                feedback(take: $take, orderBy: $orderBy) {\n                  id\n                  content\n                }\n                value\n              }\n            }\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query Submission($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          outputFile {\n            id\n            name\n          }\n        }\n      }\n    "): (typeof documents)["\n      query Submission($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          outputFile {\n            id\n            name\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation UpdateSubmission(\n        $data: SubmissionUpdateInput!\n        $where: SubmissionWhereUniqueInput!\n      ) {\n        updateOneSubmission(data: $data, where: $where) {\n          id\n          type {\n            name\n            id\n          }\n        }\n      }\n    "): (typeof documents)["\n      mutation UpdateSubmission(\n        $data: SubmissionUpdateInput!\n        $where: SubmissionWhereUniqueInput!\n      ) {\n        updateOneSubmission(data: $data, where: $where) {\n          id\n          type {\n            name\n            id\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query LabelTypeahead(\n        $where: LabelWhereInput\n        $take: Int\n        $orderBy: [LabelOrderByWithRelationInput!]\n        $distinct: [LabelScalarFieldEnum!]\n      ) {\n        labels(\n          where: $where\n          take: $take\n          orderBy: $orderBy\n          distinct: $distinct\n        ) {\n          id\n          value\n          feedback {\n            content\n          }\n        }\n      }\n    "): (typeof documents)["\n      query LabelTypeahead(\n        $where: LabelWhereInput\n        $take: Int\n        $orderBy: [LabelOrderByWithRelationInput!]\n        $distinct: [LabelScalarFieldEnum!]\n      ) {\n        labels(\n          where: $where\n          take: $take\n          orderBy: $orderBy\n          distinct: $distinct\n        ) {\n          id\n          value\n          feedback {\n            content\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query ProductTypeahead(\n        $take: Int\n        $where: ProductWhereInput\n        $distinct: [ProductScalarFieldEnum!]\n        $orderBy: [ProductOrderByWithRelationInput!]\n      ) {\n        products(\n          take: $take\n          where: $where\n          distinct: $distinct\n          orderBy: $orderBy\n        ) {\n          id\n          category {\n            id\n          }\n          family\n          description\n          line\n          manufacturer {\n            id\n          }\n          manufacturerPartNumbers\n          model\n          name\n          searchFamily\n          searchLine\n          searchName\n          updatedAt\n          createdAt\n          attributes {\n            id\n          }\n          id\n        }\n      }\n    "): (typeof documents)["\n      query ProductTypeahead(\n        $take: Int\n        $where: ProductWhereInput\n        $distinct: [ProductScalarFieldEnum!]\n        $orderBy: [ProductOrderByWithRelationInput!]\n      ) {\n        products(\n          take: $take\n          where: $where\n          distinct: $distinct\n          orderBy: $orderBy\n        ) {\n          id\n          category {\n            id\n          }\n          family\n          description\n          line\n          manufacturer {\n            id\n          }\n          manufacturerPartNumbers\n          model\n          name\n          searchFamily\n          searchLine\n          searchName\n          updatedAt\n          createdAt\n          attributes {\n            id\n          }\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetProductTypeaheadItems(\n        $take: Int\n        $where: ProductWhereInput\n        $distinct: [ProductScalarFieldEnum!]\n        $orderBy: [ProductOrderByWithRelationInput!]\n      ) {\n        products(\n          take: $take\n          where: $where\n          distinct: $distinct\n          orderBy: $orderBy\n        ) {\n          id\n          category {\n            id\n          }\n          family\n          description\n          line\n          manufacturer {\n            id\n          }\n          manufacturerPartNumbers\n          model\n          name\n          searchFamily\n          searchLine\n          searchName\n          updatedAt\n          createdAt\n          attributes {\n            id\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetProductTypeaheadItems(\n        $take: Int\n        $where: ProductWhereInput\n        $distinct: [ProductScalarFieldEnum!]\n        $orderBy: [ProductOrderByWithRelationInput!]\n      ) {\n        products(\n          take: $take\n          where: $where\n          distinct: $distinct\n          orderBy: $orderBy\n        ) {\n          id\n          category {\n            id\n          }\n          family\n          description\n          line\n          manufacturer {\n            id\n          }\n          manufacturerPartNumbers\n          model\n          name\n          searchFamily\n          searchLine\n          searchName\n          updatedAt\n          createdAt\n          attributes {\n            id\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetProductsInfinite(\n    $take: Int\n    $where: ProductWhereInput\n    $orderBy: [ProductOrderByWithRelationInput!]\n    $cursor: ProductWhereUniqueInput\n    $aggregateProductWhere2: ProductWhereInput\n  ) {\n    products(take: $take, where: $where, orderBy: $orderBy, cursor: $cursor) {\n      attributes {\n        id\n        value\n      }\n      category {\n        id\n        name\n      }\n      createdAt\n      description\n      family\n      id\n      line\n      manufacturer {\n        id\n        name\n      }\n      manufacturerPartNumbers\n      model\n      name\n      searchFamily\n      searchLine\n      searchName\n      updatedAt\n    }\n    aggregateProduct(where: $aggregateProductWhere2) {\n      _count {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetProductsInfinite(\n    $take: Int\n    $where: ProductWhereInput\n    $orderBy: [ProductOrderByWithRelationInput!]\n    $cursor: ProductWhereUniqueInput\n    $aggregateProductWhere2: ProductWhereInput\n  ) {\n    products(take: $take, where: $where, orderBy: $orderBy, cursor: $cursor) {\n      attributes {\n        id\n        value\n      }\n      category {\n        id\n        name\n      }\n      createdAt\n      description\n      family\n      id\n      line\n      manufacturer {\n        id\n        name\n      }\n      manufacturerPartNumbers\n      model\n      name\n      searchFamily\n      searchLine\n      searchName\n      updatedAt\n    }\n    aggregateProduct(where: $aggregateProductWhere2) {\n      _count {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetSubmissionsNameTypeahead(\n        $take: Int\n        $orderBy: [SubmissionOrderByWithRelationInput!]\n        $where: SubmissionWhereInput\n      ) {\n        submissions(take: $take, orderBy: $orderBy, where: $where) {\n          id\n          file {\n            name\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetSubmissionsNameTypeahead(\n        $take: Int\n        $orderBy: [SubmissionOrderByWithRelationInput!]\n        $where: SubmissionWhereInput\n      ) {\n        submissions(take: $take, orderBy: $orderBy, where: $where) {\n          id\n          file {\n            name\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetVendorsTypeahead(\n        $where: VendorWhereInput\n        $orderBy: [VendorOrderByWithRelationInput!]\n        $take: Int\n      ) {\n        vendors(where: $where, orderBy: $orderBy, take: $take) {\n          id\n          name\n        }\n      }\n    "): (typeof documents)["\n      query GetVendorsTypeahead(\n        $where: VendorWhereInput\n        $orderBy: [VendorOrderByWithRelationInput!]\n        $take: Int\n      ) {\n        vendors(where: $where, orderBy: $orderBy, take: $take) {\n          id\n          name\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSubmissionsInfinite(\n    $where: SubmissionWhereInput\n    $take: Int\n    $cursor: SubmissionWhereUniqueInput\n    $orderBy: [SubmissionOrderByWithRelationInput!]\n    $aggregateSubmissionWhere2: SubmissionWhereInput\n  ) {\n    submissions(\n      where: $where\n      take: $take\n      cursor: $cursor\n      orderBy: $orderBy\n    ) {\n      id\n      createdAt\n      submittedFor\n      metadata {\n        value\n        key\n      }\n      type {\n        name\n        type\n      }\n      file {\n        id\n        name\n      }\n      outputFile {\n        id\n        name\n      }\n      vendor {\n        id\n        name\n      }\n      transformations {\n        id\n      }\n      validations {\n        id\n      }\n      status\n      validationStatus\n    }\n    aggregateSubmission(where: $aggregateSubmissionWhere2) {\n      _count {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetSubmissionsInfinite(\n    $where: SubmissionWhereInput\n    $take: Int\n    $cursor: SubmissionWhereUniqueInput\n    $orderBy: [SubmissionOrderByWithRelationInput!]\n    $aggregateSubmissionWhere2: SubmissionWhereInput\n  ) {\n    submissions(\n      where: $where\n      take: $take\n      cursor: $cursor\n      orderBy: $orderBy\n    ) {\n      id\n      createdAt\n      submittedFor\n      metadata {\n        value\n        key\n      }\n      type {\n        name\n        type\n      }\n      file {\n        id\n        name\n      }\n      outputFile {\n        id\n        name\n      }\n      vendor {\n        id\n        name\n      }\n      transformations {\n        id\n      }\n      validations {\n        id\n      }\n      status\n      validationStatus\n    }\n    aggregateSubmission(where: $aggregateSubmissionWhere2) {\n      _count {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query getSubmissionTransformations(\n        $where: SubmissionWhereUniqueInput!\n        $transformationsWhere2: TransformationWhereInput\n      ) {\n        submission(where: $where) {\n          transformations(where: $transformationsWhere2) {\n            processed\n            outputFile {\n              name\n              id\n            }\n            id\n            name\n            status\n            type {\n              templateId\n              name\n              id\n              description\n            }\n            metadata {\n              key\n              value\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query getSubmissionTransformations(\n        $where: SubmissionWhereUniqueInput!\n        $transformationsWhere2: TransformationWhereInput\n      ) {\n        submission(where: $where) {\n          transformations(where: $transformationsWhere2) {\n            processed\n            outputFile {\n              name\n              id\n            }\n            id\n            name\n            status\n            type {\n              templateId\n              name\n              id\n              description\n            }\n            metadata {\n              key\n              value\n            }\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PredictionRows(\n    $where: PredictionWhereInput\n    $feedbackOrderBy: [FeedbackOrderByWithRelationInput!] = { createdAt: desc }\n    $feedbackTake: Int = 1\n  ) {\n    predictions(where: $where) {\n      transformation {\n        type {\n          category\n        }\n      }\n      id\n      createdAt\n      metadata {\n        key\n        value\n        updatedAt\n      }\n      labels {\n        id\n        value\n        name\n        confidence\n        updatedAt\n        feedback(orderBy: $feedbackOrderBy, take: $feedbackTake) {\n          id\n          upvote\n          content\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PredictionRows(\n    $where: PredictionWhereInput\n    $feedbackOrderBy: [FeedbackOrderByWithRelationInput!] = { createdAt: desc }\n    $feedbackTake: Int = 1\n  ) {\n    predictions(where: $where) {\n      transformation {\n        type {\n          category\n        }\n      }\n      id\n      createdAt\n      metadata {\n        key\n        value\n        updatedAt\n      }\n      labels {\n        id\n        value\n        name\n        confidence\n        updatedAt\n        feedback(orderBy: $feedbackOrderBy, take: $feedbackTake) {\n          id\n          upvote\n          content\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SubmitManyFeedback($data: [FeedbackCreateManyInput!]!) {\n    createManyFeedback(data: $data) {\n      count\n    }\n  }\n"): (typeof documents)["\n  mutation SubmitManyFeedback($data: [FeedbackCreateManyInput!]!) {\n    createManyFeedback(data: $data) {\n      count\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetNormalizeTransformation(\n        $where: SubmissionWhereUniqueInput!\n        $transformationsWhere2: TransformationWhereInput\n        $orderBy: [TransformationOrderByWithRelationInput!]\n      ) {\n        submission(where: $where) {\n          transformations(where: $transformationsWhere2, orderBy: $orderBy) {\n            id\n            outputFileId\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetNormalizeTransformation(\n        $where: SubmissionWhereUniqueInput!\n        $transformationsWhere2: TransformationWhereInput\n        $orderBy: [TransformationOrderByWithRelationInput!]\n      ) {\n        submission(where: $where) {\n          transformations(where: $transformationsWhere2, orderBy: $orderBy) {\n            id\n            outputFileId\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetSubmissionType($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          id\n          type {\n            id\n            name\n            outputColumns {\n              name\n              dataType\n              description\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetSubmissionType($where: SubmissionWhereUniqueInput!) {\n        submission(where: $where) {\n          id\n          type {\n            id\n            name\n            outputColumns {\n              name\n              dataType\n              description\n            }\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetVendorsInfinite(\n    $cursor: VendorWhereUniqueInput\n    $where: VendorWhereInput\n    $take: Int\n    $aggregateVendorWhere2: VendorWhereInput\n  ) {\n    vendors(cursor: $cursor, take: $take, where: $where) {\n      id\n      vendorId\n      name\n      email\n      totalSubmissions: _count {\n        submissions\n      }\n    }\n    aggregateVendor(where: $aggregateVendorWhere2) {\n      _count {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetVendorsInfinite(\n    $cursor: VendorWhereUniqueInput\n    $where: VendorWhereInput\n    $take: Int\n    $aggregateVendorWhere2: VendorWhereInput\n  ) {\n    vendors(cursor: $cursor, take: $take, where: $where) {\n      id\n      vendorId\n      name\n      email\n      totalSubmissions: _count {\n        submissions\n      }\n    }\n    aggregateVendor(where: $aggregateVendorWhere2) {\n      _count {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetVendorsTypeaheadItems(\n        $where: VendorWhereInput\n        $orderBy: [VendorOrderByWithRelationInput!]\n        $take: Int\n      ) {\n        vendors(where: $where, orderBy: $orderBy, take: $take) {\n          id\n          name\n          vendorId\n          email\n        }\n      }\n    "): (typeof documents)["\n      query GetVendorsTypeaheadItems(\n        $where: VendorWhereInput\n        $orderBy: [VendorOrderByWithRelationInput!]\n        $take: Int\n      ) {\n        vendors(where: $where, orderBy: $orderBy, take: $take) {\n          id\n          name\n          vendorId\n          email\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation PrepareFileDownload($fileId: Float!) {\n        prepareFileDownload(fileId: $fileId) {\n          signedUrl\n        }\n      }\n    "): (typeof documents)["\n      mutation PrepareFileDownload($fileId: Float!) {\n        prepareFileDownload(fileId: $fileId) {\n          signedUrl\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ProcessSubmission($submissionId: Float!) {\n    processSubmission(submissionId: $submissionId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation ProcessSubmission($submissionId: Float!) {\n    processSubmission(submissionId: $submissionId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation ResetSubmission($submissionId: Float!) {\n        resetSubmission(submissionId: $submissionId) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation ResetSubmission($submissionId: Float!) {\n        resetSubmission(submissionId: $submissionId) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateTransformation(\n    $data: TransformationUpdateInput!\n    $where: TransformationWhereUniqueInput!\n  ) {\n    updateOneTransformation(data: $data, where: $where) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTransformation(\n    $data: TransformationUpdateInput!\n    $where: TransformationWhereUniqueInput!\n  ) {\n    updateOneTransformation(data: $data, where: $where) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query Organization($where: OrganizationWhereUniqueInput!) {\n        organization(where: $where) {\n          id\n          name\n        }\n      }\n    "): (typeof documents)["\n      query Organization($where: OrganizationWhereUniqueInput!) {\n        organization(where: $where) {\n          id\n          name\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SubmissionTypes($where: SubmissionTypeWhereInput) {\n    submissionTypes(where: $where) {\n      id\n      createdAt\n      updatedAt\n      name\n      description\n      organizationId\n      outputColumns {\n        name\n        dataType\n        description\n      }\n      type\n    }\n  }\n"): (typeof documents)["\n  query SubmissionTypes($where: SubmissionTypeWhereInput) {\n    submissionTypes(where: $where) {\n      id\n      createdAt\n      updatedAt\n      name\n      description\n      organizationId\n      outputColumns {\n        name\n        dataType\n        description\n      }\n      type\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query getVendorSubmissionTypes($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          submissionTypes {\n            id\n            createdAt\n            updatedAt\n            name\n            description\n            organizationId\n            outputColumns {\n              name\n              dataType\n              description\n            }\n            type\n          }\n        }\n      }\n    "): (typeof documents)["\n      query getVendorSubmissionTypes($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          submissionTypes {\n            id\n            createdAt\n            updatedAt\n            name\n            description\n            organizationId\n            outputColumns {\n              name\n              dataType\n              description\n            }\n            type\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserById($where: UserWhereUniqueInput!) {\n    user(where: $where) {\n      email\n      createdAt\n      firstName\n      id\n      lastName\n      organization {\n        id\n        name\n      }\n      partnerId\n      role\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query UserById($where: UserWhereUniqueInput!) {\n    user(where: $where) {\n      email\n      createdAt\n      firstName\n      id\n      lastName\n      organization {\n        id\n        name\n      }\n      partnerId\n      role\n      updatedAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query VendorSubmissionTypes($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          normalizeMappings {\n            id\n            submissionType {\n              id\n              name\n              outputColumns {\n                name\n                dataType\n                description\n              }\n              updatedAt\n            }\n            mapping\n            examples\n          }\n        }\n      }\n    "): (typeof documents)["\n      query VendorSubmissionTypes($where: VendorWhereUniqueInput!) {\n        vendor(where: $where) {\n          normalizeMappings {\n            id\n            submissionType {\n              id\n              name\n              outputColumns {\n                name\n                dataType\n                description\n              }\n              updatedAt\n            }\n            mapping\n            examples\n          }\n        }\n      }\n    "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;