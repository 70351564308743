import { graphql } from "@/gql/gql";
import {
  GetSubmissionsNameTypeaheadQueryVariables,
  QueryMode,
  SortOrder,
} from "@/gql/graphql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Props = {
  vendorId?: number;
  typeahead?: string;
  searchVars?: GetSubmissionsNameTypeaheadQueryVariables["where"];
};

export const useGetSubmissionsNameTypeahead = ({
  vendorId,
  typeahead = "",
  searchVars,
}: Props) => {
  return useGraphqlQuery({
    queryKey: ["GetSubmissionsNameTypeahead", vendorId, typeahead, searchVars],
    query: graphql(`
      query GetSubmissionsNameTypeahead(
        $take: Int
        $orderBy: [SubmissionOrderByWithRelationInput!]
        $where: SubmissionWhereInput
      ) {
        submissions(take: $take, orderBy: $orderBy, where: $where) {
          id
          file {
            name
          }
        }
      }
    `),
    variables: {
      take: 10,
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
      where: {
        ...searchVars,
        file: {
          is: {
            name: {
              contains: typeahead,
              mode: QueryMode.Insensitive,
            },
          },
        },
        vendorId:
          vendorId != null
            ? {
                equals: vendorId,
              }
            : undefined,
      },
    },
  });
};
